import RA from "../../components/forms/Radio.module.css";
import R from "../../components/forms/Radiogroup.module.css";

import { useState } from "react";
import { Radio, RadioGroup } from "react-aria-components";
import { DeleteModal } from "../../components/modal/DeleteModal.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { useStandardMutation } from "../api/useStandardMutation.ts";
import { deleteIpAddressMutation, ipQueryAtom } from "../network/ipAddress/apiOperations.ts";
import type { DeleteVmMutation } from "./VmView.tsx";
import type { VirtualMachineLoc } from "./vmQuery.ts";

export interface VmDeleteModalProps {
    vm: VirtualMachineLoc;
    deleteVmMutation: DeleteVmMutation;
}

export function VmDeleteModal({ vm, deleteVmMutation }: VmDeleteModalProps) {
    //#region Hooks
    const delIpMutation = useStandardMutation(deleteIpAddressMutation);

    const ips = useSuspenseQueryAtom(ipQueryAtom(vm.location));
    const attachedPublicIp = [...ips.values()].find(
        (ip) => ip?.assigned_to_resource_type === "virtual_machine" && ip.assigned_to === vm.uuid,
    );

    const [deleteFloating, setDeleteFloating] = useState<string>("");
    //#endregion

    const { name } = vm;
    const deleteAlsoIp = deleteFloating === "yes" && attachedPublicIp;

    async function deleteAction() {
        await deleteVmMutation.mutateAsync({ uuid: vm.uuid, location: vm.location });
        if (deleteAlsoIp) {
            await delIpMutation.mutateAsync({ location: vm.location, address: attachedPublicIp.address });
        }
    }

    return (
        // TODO: Use a shared visual component with `DeleteLoadBalancerModal`
        <DeleteModal
            title="Delete Resource"
            modalAction={deleteAction}
            isActionDisabled={attachedPublicIp && deleteFloating === ""}
        >
            <p className="pb-3">
                Deleting a resource "{name}" will permanently remove it and will not be accessible after that.
            </p>
            <p className="pb-3">
                Deleting a resource will also delete your backups and snapshots and the machine cannot be restored after
                that. If you wish to keep the backups and snapshots consider stopping the machine instead.
            </p>

            {!!attachedPublicIp && (
                <>
                    <p className="pb-3">
                        You have a floating IP <b>{attachedPublicIp.address}</b> assigned to this resource.
                    </p>

                    <p className="font-size-subtitle pb-2">What would you like to do?</p>
                    <RadioGroup
                        className={R.RadioGroup}
                        aria-label="Keep or delete floating IP"
                        value={deleteFloating}
                        orientation="horizontal"
                        onChange={setDeleteFloating}
                    >
                        <Radio className={RA.Radio} value="no">
                            Keep floating IP
                        </Radio>
                        <Radio className={RA.Radio} value="yes">
                            Delete floating IP
                        </Radio>
                    </RadioGroup>
                </>
            )}
        </DeleteModal>
    );
}
