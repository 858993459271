import FF from "../../components/forms/FormField.module.css";

import type { VirtualMachineStorage } from "@warrenio/api-spec/spec.oats.gen";
import { useState } from "react";
import { last } from "remeda";
import { FormField } from "../../components/forms/FormField.tsx";
import { FormMaxWidthWrapper } from "../../components/forms/FormMaxWidthWrapper.tsx";
import { WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../api/useStandardMutation.ts";
import { VmStopModalHeaderBlock } from "./ModalHeaderBlock.tsx";
import { VmSizeSelect } from "./VmSizeSelect.tsx";
import { extractOsFields } from "./os/os.ts";
import { addDiskMutation, resizeDiskMutation, type VirtualMachineLoc } from "./vmQuery.ts";
import { getDiskPackages, getSizeComponentRanges, useSizeParams, type SizeValue } from "./vmSizeSelectUtils.ts";

const SIZE_DISABLED = 0;

export function DiskAddOrResizeModal({
    vm,
    disk,
}: {
    vm: VirtualMachineLoc;
    /** Selected disk to resize, or null if adding a new disk */
    disk: VirtualMachineStorage | null;
}) {
    const { status, location, designated_pool_uuid, uuid } = vm;

    //region Hooks
    const createMutation = useStandardMutation(addDiskMutation);
    const resizeMutation = useStandardMutation(resizeDiskMutation);

    const sizeParams = useSizeParams();
    const ranges = getSizeComponentRanges(sizeParams, extractOsFields(vm), { minDiskSize: disk?.size ?? 0 });
    const currentDiskSize = ranges.ssd[0];
    const [size, setSize] = useState<SizeValue>({
        disks: currentDiskSize,
        vcpu: SIZE_DISABLED,
        ram: SIZE_DISABLED,
        isCustom: true,
    });

    //endregion Hooks

    function handleChangeSize(newValue: SizeValue) {
        setSize(newValue);
    }

    async function addAdditionalDisk() {
        await createMutation.mutateAsync({
            location,
            body: {
                uuid,
                size_gb: size.disks,
            },
        });
    }

    async function resizeDisk() {
        await resizeMutation.mutateAsync({
            location,
            body: {
                uuid,
                disk_uuid: disk!.uuid,
                size_gb: size.disks,
                check_vm_state: false,
            },
        });
    }

    return (
        <WModalContent
            title={disk ? "Resize Disk" : "Add Additional Disk"}
            label={disk ? "Resize" : "Add"}
            footerNotice={
                disk && status === "running" && disk.primary
                    ? "Warning: It is recommended to stop the VM before resizing boot disk."
                    : undefined
            }
            modalAction={disk ? resizeDisk : addAdditionalDisk}
        >
            <VmStopModalHeaderBlock vm={vm} showStopButton={!!disk} />

            <FormMaxWidthWrapper>
                <FormField wide label="Size" block isRequired>
                    <div className={FF.FormFieldRadioGroup}>
                        <VmSizeSelect
                            vmData={{ location, designated_pool_uuid, ...extractOsFields(vm) }}
                            ranges={ranges}
                            value={size}
                            onChange={handleChangeSize}
                            packages={getDiskPackages(extractOsFields(vm), last(ranges.ssd)!)}
                            diskOnly
                            isDiskPrimary={disk ? disk.primary : false}
                        />
                    </div>
                </FormField>
            </FormMaxWidthWrapper>

            {disk && (
                <p>
                    Disk size can only be increased. This action is not reversible. Minimum disk size:{" "}
                    <b>{currentDiskSize} GB</b>.
                </p>
            )}
        </WModalContent>
    );
}
