import type { VirtualMachineStorageReplica } from "@warrenio/api-spec/spec.oats.gen";
import { DeleteModal } from "../../components/modal/DeleteModal.tsx";
import { capitalize } from "../../utils/string.ts";
import { useStandardMutation } from "../api/useStandardMutation.ts";
import { deleteReplicaMutation, type VirtualMachineLoc } from "./vmQuery.ts";

export function VmDeleteReplicaModal({
    vm,
    replica,
}: {
    vm: VirtualMachineLoc;
    replica: VirtualMachineStorageReplica;
}) {
    const deleteMutation = useStandardMutation(deleteReplicaMutation);

    const { type } = replica;
    return (
        <DeleteModal
            inTable
            title={`Delete ${capitalize(type)}`}
            modalAction={async () => {
                await deleteMutation.mutateAsync({
                    location: vm.location,
                    body: {
                        replica_uuid: replica.uuid,
                        type: replica.type,
                    },
                });
            }}
        >
            Deleting a {type} will permanently remove it.
        </DeleteModal>
    );
}
