import type { VirtualMachineStorageReplica } from "@warrenio/api-spec/spec.oats.gen";
import { WModalButton } from "../../components/button/WToolButton.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { capitalize } from "../../utils/string.ts";
import { useGenerateUuid } from "../../utils/useGenerateUuid.ts";
import { useStandardMutation } from "../api/useStandardMutation.ts";
import { ModalHeaderBlock } from "./ModalHeaderBlock.tsx";
import { rebuildFromReplicaMutation, type VirtualMachineLoc } from "./vmQuery.ts";

export function VmRestoreFromReplicaModal({
    vm,
    replica,
    inTable = false,
}: {
    vm: VirtualMachineLoc;
    replica: VirtualMachineStorageReplica;
    inTable?: boolean;
}) {
    const restoreMutation = useStandardMutation(rebuildFromReplicaMutation);
    const requestUuid = useGenerateUuid(undefined);
    const { type } = replica;
    return (
        <WModal button={<WModalButton label="Restore" icon="jp-return-icon" inTable={inTable} />}>
            <WModalContent
                title={`Restore from ${capitalize(type)}`}
                label="Restore"
                modalAction={async () => {
                    await restoreMutation.mutateAsync({
                        location: vm.location,
                        body: {
                            replica_uuid: replica.uuid,
                            uuid: vm.uuid,
                            type: replica.type,
                        },
                        requestUuid,
                    });
                }}
            >
                {/*TODO: siin tuleks välja mõelda parem tekst, vähemalt selle alumise vm.uuid osas, see kuidagi ei ole selge*/}
                Restoring from {type} will set the machine state to the selected {type} state.
                <br />
                <ModalHeaderBlock vm={vm} replica={replica} showSize />
            </WModalContent>
        </WModal>
    );
}
