import type { VirtualMachineStorageReplica } from "@warrenio/api-spec/spec.oats.gen";
import { CurrencyMonthly } from "../../components/l10n/Currency.tsx";
import { PricingModal } from "../pricing/PricingModal.tsx";
import type { VirtualMachineWithAssigned } from "./joinAssignedQuery.ts";
import { getPrimaryStorage } from "./vmEntityUtils.ts";
import { getOptionalVmPool } from "./vmLogic.ts";

export function VmEstimatedReplicaPriceModal({
    vm,
    type,
}: {
    vm: VirtualMachineWithAssigned;
    type: VirtualMachineStorageReplica["type"];
}) {
    const { location, priceCalc } = vm;

    const { size } = getPrimaryStorage(vm);
    const estimatedPrice = priceCalc.getReplicaPrice({ size, type });

    return (
        <div className="pb-4">
            <PricingModal defaultLocation={location} defaultHostPool={getOptionalVmPool(vm)}>
                Estimated price per {type} is <CurrencyMonthly value={estimatedPrice.hourly} /> per month.
            </PricingModal>
        </div>
    );
}
