import type { VirtualMachineStorage } from "@warrenio/api-spec/spec.oats.gen";
import { DeleteModal } from "../../components/modal/DeleteModal.tsx";
import { useStandardMutation } from "../api/useStandardMutation.ts";
import { deleteDiskMutation, type VirtualMachineLoc } from "./vmQuery.ts";

export function DiskDeleteModal({
    vm: { location, uuid },
    disk: { uuid: storage_uuid, primary },
}: {
    vm: VirtualMachineLoc;
    disk: VirtualMachineStorage;
}) {
    const mutation = useStandardMutation(deleteDiskMutation);

    return (
        <DeleteModal
            inTable
            title="Delete Disk"
            isDisabled={primary}
            modalAction={async () => {
                await mutation.mutateAsync({ location, body: { uuid, storage_uuid } });
            }}
        >
            Deleting a disk will permanently remove it and will not be accessible after that.
        </DeleteModal>
    );
}
