import { createLazyFileRoute } from "@tanstack/react-router";
import { VmView } from "../../../../modules/compute/VmView.tsx";

export const Route = createLazyFileRoute("/_main/compute/vm/$location/$vmId/")({
    component: Component,
});

function Component() {
    const { vmId, location } = Route.useParams();
    return <VmView key={vmId} vmId={vmId} location={location} />;
}
