import { useState, type ReactNode } from "react";
import { link } from "../../components/Action.tsx";
import { Toolbar } from "../../components/Toolbar.tsx";
import { WModalButton, WToolButton } from "../../components/button/WToolButton.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../api/useStandardMutation.ts";
import { ChangePasswordModal } from "./ChangePasswordModal.tsx";
import { ReinstallModal } from "./ReinstallModal.tsx";
import { VmCloneModal } from "./VmCloneModal.tsx";
import { VmResizeToolbarButton } from "./VmResizeModal.tsx";
import type { VirtualMachineWithAssigned } from "./joinAssignedQuery.ts";
import { startVmInRescueMutation, startVmMutation, stopVmMutation } from "./vmQuery.ts";

export interface VmToolbarProps {
    vm: VirtualMachineWithAssigned;
    deleteModal: ReactNode;
}

export function VmToolbar({ vm, deleteModal }: VmToolbarProps) {
    //#region Hooks
    const startMutation = useStandardMutation(startVmMutation);
    const stopMutation = useStandardMutation(stopVmMutation, { force: false });
    const forceStopMutation = useStandardMutation(stopVmMutation, { force: true });
    const startInRescueMutation = useStandardMutation(startVmInRescueMutation);

    const [rebootInProgress, setRebootInProgress] = useState(false);

    //#endregion

    const { name, status, uuid, location } = vm;

    return (
        <Toolbar>
            <WToolButton
                label="Start"
                icon="jp-icon-run"
                isDisabled={status !== "stopped"}
                action={async () => {
                    await startMutation.mutateAsync({ uuid, location });
                }}
            />

            <WModal button={<WModalButton label="Stop" icon="jp-icon-stop" isDisabled={status !== "running"} />}>
                <WModalContent
                    title="Stop Resource"
                    label="Stop"
                    modalAction={async () => {
                        await stopMutation.mutateAsync({ uuid, location });
                    }}
                >
                    This will shut down your resource "{name}". If graceful shutdown does not finish in 120 seconds,
                    then a forceful stop will be carried out. For stopped resources you will only be charged for the
                    storage.
                </WModalContent>
            </WModal>

            <WModal button={<WModalButton label="Force Stop" icon="jp-power-icon" isDisabled={status === "stopped"} />}>
                <WModalContent
                    title="Force Stop Resource"
                    label="Force Stop"
                    modalAction={async () => {
                        await forceStopMutation.mutateAsync({ uuid, location });
                    }}
                >
                    This will forcefully shut down your resource "{name}". For stopped resources you will only be
                    charged for the storage.
                </WModalContent>
            </WModal>

            <WModal button={<WModalButton label="Reboot" icon="jp-time-reverse-icon" isDisabled={rebootInProgress} />}>
                <WModalContent
                    title="Reboot Resource"
                    label="Reboot"
                    modalAction={async () => {
                        setRebootInProgress(true);
                        await forceStopMutation.mutateAsync({ uuid, location });
                        await startMutation.mutateAsync({ uuid, location });
                        setRebootInProgress(false);
                    }}
                >
                    This will reboot your resource "{name}".
                </WModalContent>
            </WModal>

            <VmCloneModal vm={vm} />

            <WToolButton
                label="Terminal"
                icon="jp-monitor-icon"
                action={link({
                    to: "/console/$location/$vmId",
                    params: { location, vmId: uuid },
                    target: "_blank",
                })}
            />

            <VmResizeToolbarButton vm={vm} />

            <ChangePasswordModal vm={vm} />

            <WModal
                button={<WModalButton label="Rescue Mode" icon="jp-help-icon" isDisabled={vm.status === "running"} />}
            >
                <WModalContent
                    title="Start Resource in Rescue Mode"
                    label="Start"
                    modalAction={async () => {
                        await startInRescueMutation.mutateAsync({ body: { uuid }, location });
                        // TODO: start VM console
                    }}
                >
                    When VM is booted in rescue mode, a live image of SystemRescue toolkit will be attached and used as
                    a boot medium. You can then use the Virtual Console to access your machine. The rescue mode is
                    enabled only for the current launch. Next time this VM is started it will follow a normal routine.
                </WModalContent>
            </WModal>

            <ReinstallModal vm={vm} />

            {deleteModal}
        </Toolbar>
    );
}
