import FF from "../../components/forms/FormField.module.css";

import { useState } from "react";
import { WModalButton } from "../../components/button/WToolButton.tsx";
import { EditableButton } from "../../components/forms/EditableButton.tsx";
import { FormField } from "../../components/forms/FormField.tsx";
import { FormMaxWidthWrapper } from "../../components/forms/FormMaxWidthWrapper.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../api/useStandardMutation.ts";
import { MachineSizeInfo } from "./MachineSizeInfo.tsx";
import { VmStopModalHeaderBlock } from "./ModalHeaderBlock.tsx";
import { VmSizeSelect } from "./VmSizeSelect.tsx";
import type { VirtualMachineWithAssigned } from "./joinAssignedQuery.ts";
import { extractOsFields } from "./os/os.ts";
import { getPrimaryStorage } from "./vmEntityUtils.ts";
import { resizeVmMutation } from "./vmQuery.ts";
import { getSizeComponentRanges, useSizeParams, type SizeValue } from "./vmSizeSelectUtils.ts";

export function VmResizeToolbarButton(props: VmResizeModalProps) {
    return (
        <WModal button={<WModalButton label="Resize" icon="jp-expand-2-icon" />}>
            <VmResizeModalContent {...props} />
        </WModal>
    );
}

export function VmResizeInfoButton(props: VmResizeModalProps) {
    return (
        <WModal
            button={
                <EditableButton action={undefined}>
                    <MachineSizeInfo vm={props.vm} />
                </EditableButton>
            }
        >
            <VmResizeModalContent {...props} />
        </WModal>
    );
}

export interface VmResizeModalProps {
    vm: VirtualMachineWithAssigned;
}

function VmResizeModalContent({ vm }: VmResizeModalProps) {
    const { vcpu, memory, status, location, uuid } = vm;
    const sizeParams = useSizeParams();

    const mutation = useStandardMutation(resizeVmMutation);

    const disk = getPrimaryStorage(vm);
    const minDiskSize = disk.size;
    const ranges = getSizeComponentRanges(sizeParams, extractOsFields(vm), { minDiskSize });
    const defaultSize: SizeValue = {
        disks: disk.size,
        vcpu,
        ram: memory,
        isCustom: true,
    };
    const [size, setSize] = useState(defaultSize);

    function handleChangeSize(newValue: SizeValue) {
        setSize(newValue);
    }

    async function resizeVm() {
        await mutation.mutateAsync({
            vm,
            body: {
                vm: {
                    uuid,
                    ram: size.ram,
                    vcpu: size.vcpu,
                },
                disk: {
                    uuid,
                    size_gb: size.disks,
                    disk_uuid: disk.uuid,
                },
            },
            location,
        });
    }

    return (
        <WModalContent
            title="Resize Virtual Machine"
            label="Resize"
            isActionDisabled={status === "running"}
            modalAction={resizeVm}
            footerNotice={status === "running" ? "Resize is possible only when machine is stopped." : undefined}
        >
            <VmStopModalHeaderBlock vm={vm} showStopButton />

            <FormMaxWidthWrapper>
                <FormField wide label="Size" block isRequired>
                    <div className={FF.FormFieldRadioGroup}>
                        <VmSizeSelect vmData={vm} value={size} ranges={ranges} onChange={handleChangeSize} isResize />
                    </div>
                </FormField>
            </FormMaxWidthWrapper>

            <p>
                Disk size can only be increased. This action is not reversible. Minimum disk size:{" "}
                <b>{minDiskSize} GB</b>.
            </p>
        </WModalContent>
    );
}
