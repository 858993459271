import { DetailWithIcon } from "../../../components/ResourceWithIcon.tsx";
import { VmImageIcon } from "../VmImageIcon.tsx";
import type { SelectedOs } from "./os.ts";
import { useOs } from "./useOs.ts";

export function OsInfo({ obj }: { obj: SelectedOs }) {
    const { os, title } = useOs(obj);

    return <DetailWithIcon title={title} icon={<VmImageIcon image={os} className="color-primary size-0.75rem" />} />;
}

export function OsTitle({ obj }: { obj: SelectedOs }) {
    const { title } = useOs(obj);

    return title;
}
